export enum NotificationTypes {
	OPEN_NOTIFICATION_LISTENER = 'OPEN_NOTIFICATION_LISTENER',
	ADD_UNREAD_NOTIFICATION = 'ADD_UNREAD_NOTIFICATION',
	ADD_UNREAD_NOTIFICATIONS = 'ADD_UNREAD_NOTIFICATIONS',
	ADD_READ_NOTIFICATION = 'ADD_READ_NOTIFICATION',
	ADD_READ_NOTIFICATIONS = 'ADD_READ_NOTIFICATIONS',
	DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
	READ_NOTIFICATION = 'READ_NOTIFICATION',
	NOTIFICATION_ERROR = 'NOTIFICATION_ERROR',
}
