import styled from 'styled-components';

export const DoughnutContainer = styled.div`
	width: 260px;
	height: 260px;
`;

export const ChartTitle = styled.p`
	margin: 0;
	font-weight: 600;
	text-transform: uppercase;
`;
