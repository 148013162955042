export enum EnemyTypes {
	SEARCH_ENEMIES_START = 'SEARCH_ENEMIES_START',
	SEARCH_ENEMIES_SUCCESS = 'SEARCH_ENEMIES_SUCCESS',
	CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT',
	SEND_ENEMY_REQUEST = 'SEND_ENEMY_REQUEST',
	OPEN_ENEMY_INFO_LISTENER = 'OPEN_ENEMY_INFO_LISTENER',
	CLOSE_ENEMY_INFO_LISTENER = 'CLOSE_ENEMY_INFO_LISTENER',
	FETCH_ENEMY_INFO_SUCCESS = 'FETCH_ENEMY_INFO_SUCCESS',
	CLEAR_ENEMY_INFO = 'CLEAR_ENEMY_INFO',
	ENEMY_ERROR = 'ENEMY_ERROR',
	FETCH_ENEMIES_START = 'FETCH_ENEMIES_START',
	FETCH_ENEMIES_SUCCESS = 'FETCH_ENEMIES_SUCCESS',
}
